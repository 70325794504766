import { AUTH_PREFIX } from "helpers/configs";
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

export const AuthViews = () => {
    return (
        <Suspense fallback={<div className="loader"></div>}>
            <Switch>
                <Route path={`${AUTH_PREFIX}/login`} component={lazy(() => import(`./login`))} />
                <Redirect from={`${AUTH_PREFIX}`} to={`${AUTH_PREFIX}/login`} />
            </Switch>
        </Suspense>
    )
}

export default AuthViews;