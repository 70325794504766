import { message } from "antd";
import axios from "axios";

const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = localStorage.getItem("iron_press_token");


const baseURL = REACT_APP_BASE_API;
const client = axios.create({
    baseURL: REACT_APP_BASE_API,
    timeout: TIMEOUT,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${TOKEN}`,
    }
});

client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status !== 200) {
            message.error(error.response.data.data);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);

const profileImage = async (imgPath) => {
    const data = await fetch(`${baseURL}admin/getFileStream?filename=${imgPath}&imageType=1`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    return data;
}

const machineImage = async (imgPath) => {
    const data = await fetch(`${baseURL}admin/getFileStream?filename=${imgPath}&imageType=2`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    return data;
}


const stationImage = async (imgPath) => {
    const data = await fetch(`${baseURL}admin/getFileStream?filename=${imgPath}&imageType=3`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    return data;
}

const sensorImage = async (imgPath) => {
    const data = await fetch(`${baseURL}admin/getFileStream?filename=${imgPath}&imageType=4`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    return data;
}



export {
    profileImage,
    machineImage,
    stationImage,
    sensorImage
};