import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col, Modal, Form, Input, Button, Upload, message, Avatar, Popconfirm } from 'antd';
import { AntDesignOutlined, DeleteOutlined } from '@ant-design/icons';
import PrivateImage from 'helpers/privateImage/privateImageProfile';
import { useEditProfile, useDeleteProfileImage } from 'hooks/Profile';
import Reset from './reset';
import "./style.css"

const Profile = (props) => {
    const { isModalOpen, setIsModalOpen, profileData } = props;

    const [form] = Form.useForm();
    const { Dragger } = Upload;
    const { mutate: editProfile, isLoading: loading } = useEditProfile();
    const { mutate: deleteProfile } = useDeleteProfileImage();
    const [files, setFiles] = useState(null);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setFiles(null)
    };

    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: 'images/*',
        onChange(info) {
            const file = info.fileList[0];
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                return false
            }
            setFiles(info.fileList);
        },
        onDrop(e) {
            const file = e.dataTransfer.files[0];
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                return false
            }
            setFiles(e.dataTransfer.files)
        },
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        return isJpgOrPng;
    };

    const confirm = (e) => {
        deleteProfile(e, {
            onSuccess: (response) => {
                if (response.status === 200) {
                    message.success(response?.data);
                    handleCancel();
                }
                else {
                    message.error(response?.message);
                }
            },
            onError: (error) => {
                console.log(error);
            }
        });
    };

    const onFinish = (values) => {
        const formData = new FormData();
        if (files) {
            formData.append(`profileImage`, files[0]?.originFileObj);
        }
        formData.append("name", values.name)
        formData.append("phone", values.phone)
        editProfile(formData, {
            onSuccess: (response) => {
                if (response.status === 200) {
                    handleCancel();
                    message.success(response?.data);
                }
                else {
                    message.error(response?.message);
                }
            },
            onError: (error) => {
                console.log(error);
            }
        });
    }

    useEffect(() => {
        if (profileData !== undefined) {
            form.setFieldsValue({
                "name": profileData?.data?.name,
                "phone": profileData?.data?.phone,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData]);


    const [isModalOpe, setIsModalOpe] = useState(false);

    const showModa = () => {
        setIsModalOpe(true);
    };

    return (
        <Fragment>
            <Modal title="Basic Modal" className="profile-modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Row className="profile">
                    <Col md={24} className="txt-center">
                        <h1>My Profile</h1>
                    </Col>
                    <Col md={24}>
                        <Form
                            form={form}
                            name="profile_info"
                            onFinish={onFinish}
                            layout="vertical">
                            <div className='txt-center profile-img'>
                                {profileData?.data?.profileImage ?
                                    <Popconfirm
                                        title="Are you sure you want to delete the profile picture?"
                                        onConfirm={confirm}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <h1><DeleteOutlined /></h1>
                                    </Popconfirm> : null}
                                <Dragger {...uploadProps} maxCount={1} beforeUpload={beforeUpload} className="new-drag">
                                    {files === null ?
                                        profileData?.data !== undefined && profileData?.data?.profileImage !== null ?
                                            <PrivateImage privateUrl={profileData?.data?.profileImage} className="size_logo" />
                                            : <Avatar size={100} icon={<AntDesignOutlined />} /> :
                                        <img
                                            src={URL.createObjectURL(files[0]?.originFileObj)}
                                            alt=".."
                                            className="size_logo"
                                        />
                                    }
                                </Dragger>
                            </div>
                            <Form.Item label="Profile Name" name="name" rules={[{ required: true, message: "Enter Your Name!" }]}>
                                <Input placeholder="Enter Your Profile Name" maxLength={35} />
                            </Form.Item>
                            <Form.Item label="Contact Number" name="phone" rules={[{ required: true, message: "Enter Your Contact Number!" }]}>
                                <Input placeholder="Enter Your Contact Number" maxLength={10} />
                            </Form.Item>
                            <div className='txt-right' >
                                <span className="reset" onClick={showModa}>Reset Password</span>
                            </div>
                            <Row gutter={[16, 0]} className="btn-part">
                                <Col md={12}>
                                    <Form.Item>
                                        <Button className="reset-btn" type="primary" onClick={handleCancel} block>Cancel</Button>
                                    </Form.Item>
                                </Col>
                                <Col md={12}>
                                    <Form.Item>
                                        <Button className="profile-btn" type="primary" htmlType="submit" loading={loading} block>Update Profile</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Reset setIsModalOpen={setIsModalOpe} isModalOpen={isModalOpe} showModal={showModa} />
        </Fragment>
    )
}
export default Profile;