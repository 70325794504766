import { message } from "antd";
import axios from "axios";

const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = localStorage.getItem("iron_press_token");


const client = axios.create({
    baseURL: REACT_APP_BASE_API,
    timeout: TIMEOUT,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${TOKEN}`,
    }
});

client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status !== 200) {
            message.error(error.response.data.data);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);

const ProfileList = async () => {
    const { data } = await client.get(`admin/getProfile`);
    return data;
}

const EditProfile = async (formData) => {
    const { data } = await client.post(`admin/editProfile`, formData);
    return data;
}

const DeleteProfileImage = async (formData) => {
    const { data } = await client.delete(`admin/removeProfileImage`, formData);
    return data;
}

const ResetPassword = async (formData) => {
    const { data } = await client.post(`admin/changePassword`, formData);
    return data;
}

const StaffList = async (pageNo) => {
    const { data } = await client.get(`admin/getAllStaff?page=${pageNo}`);
    return data;
}

const CreateStaff = async (formData) => {
    const { data } = await client.post(`admin/createOrEditStaff`, formData);
    return data;
}

const StaffDelete = async (id) => {
    const { data } = await client.delete(`admin/removeStaff?userId=${id}`);
    return data;
}

export {
    ProfileList,
    EditProfile,
    DeleteProfileImage,
    ResetPassword,
    StaffList,
    CreateStaff,
    StaffDelete
};