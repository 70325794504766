import React, { useState, useEffect } from 'react';
import { Row, Col, Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { HistoryOutlined, TeamOutlined } from "@ant-design/icons"
import { AUTH_PREFIX } from 'helpers/configs';
import Profile from 'views/app/profile/profile';
import Image from 'helpers/Image';
import { useProfileList } from 'hooks/Profile';
import PrivateImage from 'helpers/privateImage/privateImageProfile';
import { useLocation } from 'react-router-dom';
import { useStore } from 'store';
import "./style.css"




const Header = () => {
    const { isRole } = useStore();

    const search = useLocation().search;
    const machineId = new URLSearchParams(search).get('machineId');
    let history = useHistory();
    const { data: profileData, refetch } = useProfileList();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
        refetch();
    };

    const isPathActive = (path) => {
        if (path === "/") return window.location.hash === "#/" ? "nav-active" : "";
        else return window.location.hash.startsWith("#" + path) ? "nav-active" : "";
    }

    const onLogout = (e) => {
        e.preventDefault()
        localStorage.removeItem('iron_press_token');
        localStorage.removeItem('iron_press_role');
        history.push(`${AUTH_PREFIX}/login`);
    }

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="side-bar">
            <Row className='logo-bar txt-center'>
                <Col md={24} xs={24}>
                    <Link to={`/dashboard?machineId=${machineId}`}>
                        <Image name={"lg.png"} />
                    </Link>
                </Col>
            </Row>
            <Row className='menu-bar txt-center' >
                <Col md={24} xs={24}>
                    <Link className={isPathActive("/dashboard")} to={`/dashboard?machineId=${machineId}`}>
                        <div className='pointer'>
                            <HistoryOutlined />
                            <h4>Dashboard</h4>
                        </div>
                    </Link>
                </Col>
                {isRole === "ADMIN" ?
                    <Col md={24} xs={24}>
                        <Link className={isPathActive("/staff")} to={`/staff?machineId=${machineId}`}>
                            <div className='pointer'>
                                <TeamOutlined />
                                <h4>Staff</h4>
                            </div>
                        </Link>
                    </Col> : null}
            </Row>
            <Row className='footer-bar txt-center pointer'>
                <Col md={24} xs={24} >
                    <div onClick={showModal}>
                        {profileData?.data?.profileImage === null ? <Avatar>I</Avatar> :
                            <PrivateImage privateUrl={profileData?.data?.profileImage} className="size_profile" />}
                    </div>
                    <h5 onClick={(e) => onLogout(e)}>Log Out</h5>
                </Col>
            </Row>
            <Profile profileData={profileData} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </div >
    )
}

export default Header



