import { useQuery } from "react-query";
import * as api from "services/PrivateImageServices";


export const useProfileImage = (imgPath) => {
    return useQuery(["ProfileImages", imgPath], () => api.profileImage(imgPath), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}

export const useMachineImage = (imgPath) => {
    return useQuery(["MachineImages", imgPath], () => api.machineImage(imgPath), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}

export const useStationImage = (imgPath) => {
    return useQuery(["StationImages", imgPath], () => api.stationImage(imgPath), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}

export const useSensorImage = (imgPath) => {
    return useQuery(["SensorImages", imgPath], () => api.sensorImage(imgPath), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}