import { APP_PREFIX } from "helpers/configs";
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";


export const AppViews = () => {
  return (
    <Suspense fallback={<div className="loader"></div>}>
      <Switch>
        <Route path={`${APP_PREFIX}/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route path={`${APP_PREFIX}/graph`} component={lazy(() => import(`./graph`))} />
        <Route path={`${APP_PREFIX}/staff`} component={lazy(() => import(`./staff`))} />
        <Redirect from={`${APP_PREFIX}`} to={`${APP_PREFIX}/dashboard?machineId=1`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
