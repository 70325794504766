import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "services/Profileservices";


export const useProfileList = () => {
    return useQuery("ProfileList", () => api.ProfileList(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useEditProfile = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.EditProfile(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('ProfileList');
        }
    });
}

export const useDeleteProfileImage = () => {
    const queryClient = useQueryClient();
    return useMutation((formData) => api.DeleteProfileImage(formData), {
        onSuccess: () => {
            queryClient.refetchQueries('ProfileList');
        }
    });
}

export const useResetPassword = () => {
    return useMutation((formData) => api.ResetPassword(formData));
}

export const useStaffList = (pageNo) => {
    return useQuery(["StaffList", pageNo], () => api.StaffList(pageNo), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useStaffDelete = (id) => {
    return useQuery(["StaffDelete", id], () => api.StaffDelete(id), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useCreateStaff = () => {
    return useMutation((formData) => api.CreateStaff(formData));
}