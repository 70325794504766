import React from 'react'
import { Row, Col } from 'antd'
import { Switch, Route, } from "react-router-dom"
import AppViews from 'views/app'
import Header from 'layouts/utility/Header'
import Footer from 'layouts/utility/Footer'

export const AppLayout = () => {

    return (
        <div className="iron-app">
            <Row>
                <Col md={0} xs={24} lg={0}>
                    <h1 className='txt-center'>Iron Press</h1>
                </Col>
                <Col md={2} xs={0}>
                    <Header />
                </Col>
                <Col md={22} xs={0} className="body-part">
                    <Switch>
                        <Route path="" component={AppViews} />
                    </Switch>
                    <Footer />
                </Col>
            </Row>
        </div>
    )
}

export default AppLayout;