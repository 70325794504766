import create from "zustand";

export const useStore = create((set, get) => ({
    isAuthorized: localStorage.getItem("iron_press_token") === undefined ? null : localStorage.getItem("iron_press_token"),
    isRole: localStorage.getItem("iron_press_role") === undefined ? null : localStorage.getItem("iron_press_role"),
    setRole: (role) => {
        set((state) => ({
            isRole: (state.isRole = role),
        }))
    },
    checkRole: () => {
        set((state) => ({
            isRole: (state.isRole = localStorage.getItem("iron_press_role")),
        }))
    },
    setAuthorized: (token) => {
        set((state) => ({
            isAuthorized: (state.isAuthorized = token),
        }))
    },
    checkAuthorized: () => {
        set((state) => ({
            isAuthorized: (state.isAuthorized = localStorage.getItem("iron_press_token")),
        }))
    }

}));

