import React from "react";
import { Row, Col, Modal, Form, Input, Button, message } from 'antd';
import { useResetPassword } from "hooks/Profile";

const Reset = (props) => {

    const [form] = Form.useForm();
    const { isModalOpen, setIsModalOpen } = props;
    const { mutate: reset } = useResetPassword();

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = (values) => {
        reset(values, {
            onSuccess: (item) => {
                if (item?.status === 200) {
                    message.success(item?.data)
                    handleCancel();
                }
                else {
                    message.error(item?.message)
                }
            },
            onError: (error) => {
                console.log(error)
            }
        });
    }

    return (
        <Modal title="Basic Modal" className="profile-modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Row className="profile">
                <Col md={24} className="txt-center">
                    <h1>Reset Password</h1>
                </Col>
                <Col md={24}>
                    <Form
                        form={form}
                        name="profile_reset"
                        onFinish={onFinish}
                        layout="vertical">
                        <br></br><br></br>
                        <Form.Item label="Current Password" name="currentPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter your Current Password!',
                                },
                            ]} >
                            <Input.Password placeholder=" Current Password" />
                        </Form.Item>
                        <Form.Item label="New Password" name="newPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter your New Password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('currentPassword') !== value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('The current passwords & new password has been same!'));
                                    },
                                }),
                            ]} >
                            <Input.Password placeholder="New Password" />
                        </Form.Item>
                        <Form.Item label="Confirm Password" name="confirmPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Confirm your Password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('new passwords and confirm password are not the same!'));
                                    },
                                }),
                            ]}>
                            <Input.Password placeholder="Confirm Password" />
                        </Form.Item>
                        <br></br>
                        <Row gutter={[16, 0]} className="btn-part">
                            <Col md={12}>
                                <Button className="reset-btn" type="primary" onClick={handleCancel} block>Cancel</Button>
                            </Col>
                            <Col md={12}>
                                <Form.Item>
                                    <Button className="profile-btn" type="primary" htmlType="submit" block>Update Password</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
}
export default Reset;