import React from "react";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import { Switch, Route, Redirect } from "react-router-dom";
import { APP_PREFIX, AUTH_PREFIX } from "helpers/configs";


function AppInterceptor({ children, isAuthenticated, prefix, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => isAuthenticated ? (
                children
            ) : (
                <Redirect to={{ pathname: prefix, state: { from: location } }} />
            )
            }
        />
    );
}

export const Views = (props) => {
    const { location } = props;
    let PREFIX = localStorage.getItem("iron_press_token") === null ? AUTH_PREFIX : APP_PREFIX;

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={PREFIX} />
                {localStorage.getItem("iron_press_token") === null ? <AuthLayout /> : <AppLayout />}
            </Route>
            <Route path={AUTH_PREFIX}>
                <AuthLayout />
            </Route>
            <AppInterceptor prefix={PREFIX} path={APP_PREFIX} isAuthenticated={localStorage.getItem("iron_press_token")}>
                <AppLayout location={location} />
            </AppInterceptor>
        </Switch>
    )
}

export default Views;