import React, { Fragment, useEffect } from "react";
import { useProfileImage } from "hooks/PrivateImage";
import { Skeleton } from 'antd';

const PrivateImageProfile = ({ className, privateUrl }) => {
    const { data, refetch, isLoading } = useProfileImage(privateUrl);

    useEffect(() => {
        if (privateUrl !== undefined) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateUrl]);


    return (
        <Fragment>
            {
                !isLoading && privateUrl !== undefined && data !== undefined ?
                    <img className={className} src={data} alt="...." /> :
                    <Skeleton.Avatar active="true" size="large" shape="square" />
            }
        </Fragment>
    )
};

export default PrivateImageProfile;